import { Injectable, inject } from "@angular/core";
import { CommonService } from "./common.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as NoteActions from './note.actions';
import { fetchUsers, matchedUser } from "./note.actions";
import { of, throwError } from "rxjs";
import { map, switchMap, catchError, tap, filter } from "rxjs/operators";
import { Action } from "@ngrx/store";

@Injectable()
export class NoteEffect {
    private commonApi = inject(CommonService);
    action$ = inject(Actions);
    // noteAdd$ = createEffect(()=>this.action$.pipe(
    //         ofType(NoteActions.addNote),
    //         switchMap((noteObj)=> this.commonApi.getUsers().pipe(
    //                 map((response:any)=>response.filter((q:any)=>q.username == noteObj.name ? NoteActions.fetchUsersMatchNoteSuccess(q) : '')
    //                         //return (q.username == noteObj.name) //{
    //                             //console.log('matched---noteObj',q)
    //                             ///return NoteActions.fetchUsersMatchNoteSuccess(q)
    //                        // }
    //                    // })
                    
    //                 ),
    //                 tap(()=>console.log('noteObj',noteObj)),
    //                 catchError((error:{message:string}) => of(
    //                         NoteActions.fetchUsersMatchNoteError(error)
    //                     )
    //                 )  
    //             )
    //         )
    //     )
    // )


    noteAdd$ = createEffect(()=>this.action$.pipe(
            ofType(NoteActions.addNote),
            switchMap((noteObj)=> this.commonApi.getUsers().pipe(
                    map((response:any)=>NoteActions.fetchUsers({query:noteObj,users:response})),
                    // map((res:any)=>({ type:'[Note Page Fetch Users Success]', payload:{query:noteObj,users:res,yy:['tt']} })),
                    tap(()=>console.log('noteObj',noteObj)),
                    catchError((error:{message:string}) => of(
                            NoteActions.fetchUsersError(error)
                        )
                    )  
                )
            )
        )
    );

    handleCondition$ = createEffect(()=> this.action$.pipe(
        ofType(NoteActions.fetchUsers),
            switchMap((action:any)=> {
                let user:any=undefined;
                user=action.users.find((u:any)=>u.username == action.query.name);
                if(user !== undefined) return of(NoteActions.matchedUser(user));
                else return of(NoteActions.notMatchedUserError(new Error('User not matched')));
                catchError((error:{message:string}) => of(
                        NoteActions.fetchUsersError(error)
                    )
                )  
                //return of(NoteActions.matchedUser(action))
            })
        )
    );

    // handleCondition$ = createEffect(()=>this.action$.pipe(
    //     ofType(NoteActions.fetchUsersMatchNoteSuccess),
    //         switchMap((query:any)=> of(query).pipe(
    //             map((f:any)=>console.log("df",f))
    //         ))
    //     ))
    
}