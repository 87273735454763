import { Routes } from '@angular/router';
import { provideState } from '@ngrx/store';
import { selectedNoteReducer } from './selected-notes/selected.notes.reducer';
import { SelectedNotesComponent } from './selected-notes/selected-notes.component';
import { provideEffects } from '@ngrx/effects';
import { selectedNotesDetails } from './selected-notes/selected.notes.effect';

export const routes: Routes = [
    {
        path: 'select',
        loadComponent: ()=>import('./my-notes/my-notes.component').then(m=>m.MyNotesComponent),
    },
    {
        path: 'selected-notes',
        component: SelectedNotesComponent,
        providers:[
            provideState({name: 'selectedNoteReducer',reducer: selectedNoteReducer}),
            provideEffects(selectedNotesDetails)
        ]
    },
    {
        path: 'my-form',
        loadComponent: ()=>import('./my-form/my-form.component').then(m=>m.MyFormComponent),
    },
    {
        path:'',
        redirectTo:'select',
        pathMatch:'full'
    }
];
