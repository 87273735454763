import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import { fetchSelectedNotesDetails } from './selected.notes.actions';
import { selectNote } from '../note.actions';

@Injectable()
export class selectedNotesDetails {
  selectedNotes$ = createEffect(() => this.actions$.pipe(
      ofType(selectNote),
      // Call Server via service here
      map(_ => (fetchSelectedNotesDetails()))
    )
  );

  constructor(
    private actions$: Actions,
  ) {
  }
}
