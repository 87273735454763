import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Note } from '../model/note';

@Component({
  selector: 'app-selected-notes',
  standalone: true,
  imports: [],
  templateUrl: './selected-notes.component.html',
  styleUrl: './selected-notes.component.scss'
})
export class SelectedNotesComponent {
  public selectedNotes$ = new Observable<any>();

  constructor(private store: Store<any>){
    //this.selectedNotes$ = store.select(noteItems);
    store.subscribe({
      next: (w:any) => console.log("store-from-selected-notes",w)
    })
  }
}
