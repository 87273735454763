import { createReducer, on } from "@ngrx/store";
import { addNote, updateNote, removeNote, pinNote, unpinNote } from "./note.actions";
import * as noteActions from "./note.actions";
import { Note } from "./model/note";
export const initialState:any = [];
export const noteReducer = createReducer(
    initialState,
    // on(noteActions.addNote, (store, result)=>{
    //      return {actionName:result}
    // }),
    // on(noteActions.fetchUsersMatchNoteSuccess, (store, result)=>{
    //     var matchedUser:any=undefined;
    //     console.log("result",result);
    //         matchedUser = result?.users.find((f:any)=>f.username == result?.query?.name );
    //         if(matchedUser !== undefined) console.log("User name matches with the one from 'JSONPlaceHolder User Name'",result);
    //         else console.log("not matched");
    //         console.log("matchedUser",matchedUser)
      
    //     return [...store,matchedUser];
    // }),
    // on(noteActions.fetchUsersMatchNoteSuccess, (store, result)=>{
    //     var matchedUser:any=undefined;
    //     console.log("result",result);
     
    //         matchedUser = result?.users.find((f:any)=>f.username == result?.query?.name );
    //         if(matchedUser !== undefined) console.log("User name matches with the one from 'JSONPlaceHolder User Name'",result);
    //         else console.log("not matched");
    //         console.log("matchedUser",matchedUser)
      
    //     return [...store,matchedUser];
    // }),

    on(noteActions.matchedUser, (store,result)=>{
        console.log('store',store,'result',result);
        //let tt = store.users;
        let str = store.users === undefined ? store : store.users;
        return {users:[...str,result],actionName:result}
    }),
    on(noteActions.notMatchedUserError, (store,result) => {
        console.log('store',store,'actionName',result);
        let str = store.users === undefined ? store : store.users;
        return {users:[...str],actionName:result}
    }),
    on(noteActions.fetchUsersError, (store,result)=>{
        console.error("User NOT matches with the one from 'JSONPlaceHolder User Name'");
         return {users:[...store],actionName:result}
    }),
    on(noteActions.removeNote, (store,result) => store.filter((w:any)=>w.id !== result.id)),
    on(noteActions.updateNote, (store,result) => {
        let h = store.filter((w:any)=>w.id !== result.id);
        return {users:[...h,result],actionName:result}
    }),
    on(noteActions.pinNote, (store,result) => {
        let h = store.filter((w:any)=>w.id !== result.id);
        return {users:[result,...h]};
    }),
    on(noteActions.unpinNote, (store,result) => {
        let h = store.filter((w:any)=>w.id !== result.id);
        return {users:[...h,result]};
    })
);