import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MyNotesComponent } from './my-notes/my-notes.component';
import { SelectedNotesComponent } from './selected-notes/selected-notes.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,MyNotesComponent,SelectedNotesComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent{
  title = 'myNgrxApp';
}
